/* eslint-disable no-console */

import { register } from 'register-service-worker'
import store from '@/store'

if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, {
      ready () {
        // console.log(
        //   'App is being served from cache by a service worker.\n' +
        //   'For more details, visit https://goo.gl/AFskqB'
        // )
        store.commit('setPwa', {
          type: 'ready',
          message: 'Aplicația este servită din cache.'
        })
      },
      registered () {
        // console.log('Service worker has been registered.')
        store.commit('setPwa', {
          type: 'registered',
          message: 'Workerul offline este înregistrat.'
        })
      },
      cached () {
        // console.log('Content has been cached for offline use.')
        store.commit('setPwa', {
          type: 'cached',
          message: 'Aplicația a fost înregistrată în cache și poate fi folosită fara conexiune la internet.'
        })
      },
      updatefound () {
        // console.log('New content is downloading.')
        store.commit('setPwa', {
          type: 'updatefound',
          message: 'Aplicația se actualizează...'
        })
      },
      updated () {
        //console.log('New content is available; please refresh.')
        store.commit('setPwa', {
          type: 'updated',
          message: 'Aplicația a fost actualizată. Vă rugăm să închideți și să redeschideți aplicația.'
        })
      },
      offline () {
        //console.log('No internet connection found. App is running in offline mode.')
        store.commit('setPwa', {
          type: 'offline',
          message: 'Nu există conexiune la internet. Aplicația nu va avea funcționalitate completă.'
        })
      },
      error (error) {
        // console.error('Error during service worker registration:', error)
        store.commit('setPwa', {
          type: 'error',
          message: `Eroare la înregistrarea workerelor PWA: ${error}`
        })
      }
    })
  }
