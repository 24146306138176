<template>
    <div id="app">
        <transition name="main-page" mode="out-in">
        <!-- <vue-page-transition name="fade-in-right"> -->
            <router-view />
        <!-- </vue-page-transition> -->
        </transition>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'app',
    computed: {
        ...mapGetters({
        pwa: 'pwa'
        })
    },
    mounted() {
        this.$store.dispatch('getLocations')
        // this.$store.dispatch('getQuestions')
    }
}

</script>

