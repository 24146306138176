import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Cookies from "js-cookie";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Login',
        component: () => import('../views/Login.vue')
    },
    {
        path: '/instructiuni',
        name: 'Instructions',
        component: () => import('../views/Instructions.vue')
    },
    {
        path: '/vote',
        name: 'Vote',
        component: () => import('../views/Vote.vue')
    },
    {
        path: '/success',
        name: 'Success',
        component: () => import('../views/Success.vue')
    },
    {
        path: '/sesiune-expirata',
        name: 'SessionExpired',
        component: () => import('../views/SessionExpired.vue')
    },
    { path: '*', redirect: '/' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// set which pages are public without being logged in
router.beforeEach((to, from, next) => {
    if ( !Cookies.get('u_log')) {
        store.commit('resetPromise')
    }
    
    const publicPages = ['/'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');
  
    if (authRequired && !loggedIn) {
      // return next('/');
    }
  
    next();
})

export default router
