import axios from 'axios';
import Vue from 'vue';
import VueAxios from "vue-axios";

const ApiService = {
    init () {
        Vue.use(VueAxios, axios);
        Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
        
        Vue.axios.defaults.headers.common = {
            "X-Requested-With": "XMLHttpRequest",
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Accept-Language": "en"
        };
    },
    get(resource, params) {
        return Vue.axios.get(`${resource}`, params);
    },
    post(resource, params) {
        return Vue.axios.post(`${resource}`, params);
    },
    update(resource, params) {
        return Vue.axios.put(`${resource}`, params);
    },
    delete(resource, params) {
        return Vue.axios.delete(`${resource}`, params);
    },
    customRequest(data) {
        return Vue.axios(data);
    }
}
export default ApiService;