import Vue from 'vue'
import App from './App.vue'
import '@/registerServiceWorker'
import router from '@/router'
import store from '@/store'
import ApiService from '@/service/api.service'
import Vuelidate from 'vuelidate'
// import VuePageTransition from 'vue-page-transition'
import vSelect from 'vue-select';
Vue.component('v-select', vSelect);
import 'vue-select/dist/vue-select.css';

Vue.use(Vuelidate);
// Vue.use(VuePageTransition);

// Styling
import '@/scss/custom_bootstrap.scss'
import '@/scss/app.scss'

Vue.config.productionTip = false

ApiService.init();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
