import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import ApiService from '@/service/api.service'
import Cookies from "js-cookie"
import { v4 as uuidv4 } from 'uuid'

Vue.use(Vuex)

export default new Vuex.Store({

    state: {
        pwa: {
            type: null,
            message: null
        },
        status: {
            message: null,
            code: null
        },
        heetsFlavours: [
            {
                title: 'Note de fructe <br>tropicale',
                name: 'fructe-tropicale',
                color: '#FCC453'
            },
            {
                title: 'Note fructate <br>îndrăznețe și nuanțe florale',
                name: 'fructate-si-nuante-florale',
                color: '#F39D7A'
            },
            {
                title: 'Note de mentă și arome <br>de fructe de pădure',
                name: 'menta-si-fructe-de-padure',
                color: '#B7A7CE'
            },
        ],
        selectedHeetsFlavour: null,
        surveyCompleted: false,
        token: localStorage.getItem('user') || '',
        locations: null,
        rev: process.env.VUE_APP_REV,
    },

    mutations: {

        // Set pwa
        setPwa: (state, payload) => state.pwa = payload,
        
        // Reset quiz
        resetQuiz: (state) => {
            state.surveyCompleted = false;
            state.selectedHeetsFlavour = null;
            
            router.push({
                name: "Instructions"
            })
        },

        // Set locations
        setLocations: (state, locations) => {
            state.locations = locations
        },

        // Set user
        setUser: (state, response) => {
            if ( response.status == '401' ) {
                state.status.message = response.data.message
                state.status.code = response.status
                localStorage.removeItem('user')
            } else {
                state.status.message = response.message
                state.token = response.token
                localStorage.setItem('user', state.token)
                Cookies.set('u_log', '1', { expires: 0.416667 }) //10 hours
                
                router.push({
                    name: 'Instructions'
                })
            }
        },

        selectHeetsFlavour: (state, flavour) => {
            state.selectedHeetsFlavour = flavour;
        },

        // Reset quiz
        resetPromise: (state) => {
            state.token = ''
            state.status.message = null
            state.status.code = null
            localStorage.removeItem('user')
        },

        // completes quiz
        changeSurveyCompleted: (state, payload) => {
            state.surveyCompleted = payload
        }
    },

    actions: {

        // Send data to backend
        saveVote: ({ commit, state }) => {

            if (state.changeSurveyCompleted) return false;

            // Set quiz as completed
            commit('changeSurveyCompleted', true);

            // Added REV for validating app revision with the api
            const rev = state.rev;

            // Get the answers
            // const answersNew = [];
            
            return ApiService.customRequest({
                headers: {
                    'Authorization': `Bearer ${state.token}` 
                },
                method: 'POST',
                url: 'entries',
                data: {
                    uniqueId: uuidv4(),
                    answer: state.selectedHeetsFlavour.name,
                    rev: rev,
                }
            })
            .then(() => {
                router.push({ name: "Success" }, () => {
                    commit('changeSurveyCompleted', false);
                });
            })
            .catch((error) => {
                commit('resetPromise');
                console.log(error);
                alert('Folosești o versiune veche a aplicației. Te rugăm să actualizezi sau să reîmprospătezi browserul.');
            })
        },

        // Reset quiz
        resetQuizAction: ({ commit }) => {
            commit('resetQuiz');
        },

        // Check login
        checkLogin: ({ state, commit }, payload) => {
            const rev = state.rev;
            return ApiService.customRequest({
                method: 'POST',
                url: 'auth/login',
                data: {
                    username: payload.username,
                    password: payload.password,
                    rev: rev,
                }
            })
            .then((response) => {
                commit('setUser', response.data)
            })
            .catch((error) => {
                if (error.response) {
                    commit('setUser', error.response)
                }
            })
        },

        // Get locations
        getLocations: ({ commit }) => {
            return ApiService.customRequest({
                method: 'GET',
                url: 'locations'
            })
            .then((response) => {
                commit('setLocations', response.data)
            })
            .catch(() => {})
        },

        // Auth
        Authenticated: () => {
            router.push({
                name: "Instructions"
            })
        },

        // Reset quiz
        resetPromiseAction: ({ commit }) => {
            commit('resetPromise');
            router.push({
                name: "Login"
            })
        }
    },

    getters: {
        checkSurvey: (state) => state.surveyCompleted,
        pwa: (state) => state.pwa,
        dropDownLocations: (state) => state.locations,
        loginMessage: ( state ) => state.status.message,
        isAuthenticated: (state) => !!state.token,
    }
})